<template>
    <div class="pagebox">
        <!-- 顶部 -->
        <PHeader></PHeader>
        <div class="devbox">
            <div class="clear"></div>
            <div class="dev-ico">
                <i class="iconfont">&#xe748;</i>
            </div>
            <div class="dev-tit">功能开发中，敬请期待！</div>
            <div class="dev-txt" @click.prevent="$router.push('/index')">前往首页 ></div>
        </div>
        <!-- 底部 -->
        <PFooter></PFooter>
    </div>
</template>
<script>
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
export default{
    components: {
        PHeader,
        PFooter
    },
}
</script>
<style lang="less" scoped>
.devbox{
    width: 100%;
    height: 600px;
    .dev-ico{
        width: 100px;
        height: 100px;
        margin: auto;
        margin-top: 150px;
        text-align: center;
        position: relative;
        i{
            color: rgb(var(--themecolor));
            font-size: 100px;
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            animation:devico 5s linear 0s infinite;
            -webkit-animation:devico 5s linear 0s infinite;
        }
        @-webkit-keyframes devico {
            from {-webkit-transform: rotate(0deg);transform: rotate(0deg);}
            to {-webkit-transform: rotate(360deg);transform: rotate(360deg);}
        }
        @keyframes devico {
            from {-webkit-transform: rotate(0deg);transform: rotate(0deg);}
            to {-webkit-transform: rotate(360deg);transform: rotate(360deg);}
        }
    }
    .dev-tit{
        text-align: center;
        color: #666;
        font-size: 24px;
        margin-top: 40px;
    }
    .dev-txt{
        text-align: center;
        color: #666;
        font-size: 14px;
        margin-top: 10px;
        text-decoration: underline;
    }
    .dev-txt:hover{
        color: rgb(var(--themecolor));
        cursor: pointer;
    }
}

</style>